<template>
	<div class="Sobre">
		<headNav :activeNav="'sobre'"></headNav>
		<div class="p1"></div>

		<div style="margin: 0 auto; margin-top: 166px">
			<div class="bigContent">
				<div class="content clearfix">
					<div class="left">
						<div class="title">Perfil de la empresa</div>
						<span><u>Superapoyo</u></span> es una plataforma que ofrece préstamos en línea rápidos, accesibles y
						seguros. Regístrate en segundos, aplica para un préstamo y recibe tu efectivo en minutos. Vemos el mundo
						diferente. Estamos motivados por una creencia fundamental en las personas, y trabajamos juntos para
						demostrar su potencial. Asumimos riesgos que otros no tomarían para poner más poder en las manos de nuestros
						clientes.
					</div>
					<div class="right">
						<img src="@/assets/image/about_img.png" alt="" />
					</div>
				</div>
			</div>
			<div class="getbottom">
				<ul>
					<li>
						<img src="../assets/image/2_list1.png" alt="" />
						<div>
							<p class="one">Atención al cliente：</p>
							<p class="two">5585265954</p>
						</div>
					</li>
					<li>
						<img src="../assets/image/2_list1.png" alt="" />
						<div>
							<p class="one">Whatsapp：</p>
							<p class="two">5645119668</p>
						</div>
					</li>
					<li>
						<img src="../assets/image/2_list2.png" alt="" />
						<div>
							<p class="one">Email：</p>
							<p class="two">atc.superapoyo2@gmail.com</p>
							<p class="two">superapoyo.cobranza.mx@gmail.com</p>
						</div>
					</li>
					<li>
						<img src="../assets/image/2_list3.png" alt="" />
						<div>
							<p class="one">Dirección de la oficina：</p>
							<p class="two">Versalles No.15, 4th floor, 401, Cuauhtémoc,Ciudad de México</p>
						</div>
					</li>
					<li>
						<img src="../assets/image/2_list4.png" alt="" />
						<div>
							<p class="one">Tiempo laboral：</p>
							<p class="two">De lunes a viernes 9:00-18:30</p>
							<p class="two">De sábado a domingo 9:00-14:30</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<footPage></footPage>
	</div>
</template>

<script>
	import headNav from '@/components/headNav.vue'
	import footPage from '@/components/foot.vue'

	import '../components/scss/sobre.scss'

	export default {
		name: 'Sobre',
		components: { headNav, footPage },
		data() {
			return {}
		},
	}
</script>

<style></style>
