<template>
  <div class="ayuda">
    <headNav :activeNav="'ayuda'"></headNav>
    <div class="banner"></div>
    <div class="content">
      <img src="../assets/image/4_title.png" alt="">
      <ul class="btnList">
        <li v-for="item in btnList" :key="item.id" :class="item.id==changeBtn?'is-active':''">
          <button @click="changeCont(item.id)">{{item.label}}</button>
        </li>
      </ul>
      <div style="width:1075px;border-top:1px dashed #979797;padding-top:40px" v-if="changeBtn==1">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item  :name="1">
             <template slot="title">
               <transition name="slide-fade">
             <div class="collapseDiv" :class="activeNames.indexOf(1)>-1?'activeTitle':''">
               <p>Requisito indispensable:</p>
               <i v-if="activeNames.indexOf(1)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(1)>-1" class="el-icon-caret-top"></i>
             </div>
               </transition>
            </template>
            <div class="collapseCont">Edad: mayor de 18 años</div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="2">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(2)>-1?'activeTitle':''">
               <p>Trámites indispensables:</p>
               <i v-if="activeNames.indexOf(2)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(2)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>1) Tener su IFE/INE a la mano</div>
            <div>2) Tener una cuenta de débito de CUALQUIER banco y que esté a SU nombre.</div>
            <div>3) Llenar su solicitud en la aplicación</div>
            <div><b>NOTA:</b>Recuerde que no pedimos ninguna clase de anticipos y que la única forma de hacer su solicitud es por medio de la aplicación. TODO EL PROCESO ES GRATUITO.</div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div style="width:1075px;border-top:1px dashed #979797;padding-top:40px" v-if="changeBtn==2">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item  :name="1">
             <template slot="title">
               <transition name="slide-fade">
             <div class="collapseDiv" :class="activeNames.indexOf(1)>-1?'activeTitle':''">
               <p> ¿Cómo puedo solicitar mi primer préstamo?</p>
               <i v-if="activeNames.indexOf(1)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(1)>-1" class="el-icon-caret-top"></i>
             </div>
               </transition>
            </template>
            <div class="collapseCont">Entre a la app y regístrese con su número telefónico (Le recomendamos crear una contraseña a la vez) y complete los datos básicos. Después de la autorización, de clic en el botón de ¨CONFIRMAR¨, el préstamo llegará a su cuenta bancaria.</div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="2">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(2)>-1?'activeTitle':''">
               <p>¿Cómo puedo solicitar de nuevo un préstamo?</p>
               <i v-if="activeNames.indexOf(2)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(2)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>Si le rechazaron su última solicitud, puede volver a solicitar después de 7 días; Una vez que se acredite su pago podrá solicitar un segundo préstamo ¡de inmediato! Y esta vez disfrutará de un monto mayor con un costo menor.</div>
            </div>
          </el-collapse-item>
           <el-collapse-item title="Trámites indispensables:" :name="3">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(3)>-1?'activeTitle':''">
               <p>¿Cómo tener otro préstamo mientras tengo uno activo?</p>
               <i v-if="activeNames.indexOf(3)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(3)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>Lamentablemente no puede pedir otro préstamo mientras tiene uno activo. Deberá liquidar su préstamo activo para poder solicitar otro. Eso sí, una vez que liquide, podrá solicitar otro de forma inmedia.</div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="4">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(4)>-1?'activeTitle':''">
               <p>¿Cuánto tiempo tengo para confirmar mi préstamo en la App?</p>
               <i v-if="activeNames.indexOf(4)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(4)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
                Le quedan TRES días para confirmar su préstamo. Le recomendamos dar clic en ¨CONFIRMAR¨ lo antes posible. Cuando se convierta en cliente Premium disfrutará de un monto mayor con un costo menor.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="5">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(5)>-1?'activeTitle':''">
               <p>¿Cuánto tiempo tardan en depositar?</p>
               <i v-if="activeNames.indexOf(5)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(5)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
                Procesaremos su solicitud lo antes posible. Una vez aprobada tu solicitud, el depósito se completará en unos minutos. Sin embargo, debido a la diferencia en el horario laboral del Banco de México, puede haber un retraso en su transferencia. No se preocupe, si tiene circunstancias especiales, puede contactarnos al 5585265954 de 9:00 a 18:00 en horario laboral o al correo atc.superapoyo2@gmail.com.
              </div>
            </div>
          </el-collapse-item>
           <el-collapse-item title="Trámites indispensables:" :name="6">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(6)>-1?'activeTitle':''">
               <p> ¿Qué puedo hacer si falla el depósito?</p>
               <i v-if="activeNames.indexOf(6)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(6)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
               Estimado cliente, en este caso puede intentar comprobar si hay un error en el préstamo, o hay un problema con la recepción de su cuenta, y si el estado de su cuenta bancaria es normal. Si tiene circunstancias especiales, puede contactarnos al 5585265954 de 9:00 a 18:00 en horario laboral o al correo atc.superapoyo2@gmail.com. Le proporcionaremos la información más detallada y le brindaremos servicios más personalizados.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="7">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(7)>-1?'activeTitle':''">
               <p>¿Cómo usan la información que lleno en la solicitud de préstamo?</p>
               <i v-if="activeNames.indexOf(7)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(7)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
              Superapoyo usa sus respuestas para determinar si es candidato a un préstamo de nuestra app. Toda su información se encuentra segura y protegida. Nunca proporcionaremos su información a terceros sin su permiso. Puede leer nuestro Aviso de Privacidad dentro de la app.              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div style="width:1075px;border-top:1px dashed #979797;padding-top:40px" v-if="changeBtn==3">
        <el-collapse v-model="activeNames" @change="handleChange">

            <el-collapse-item   :name="3">
                <template slot="title">
                    <div class="collapseDiv" :class="activeNames.indexOf(3)>-1?'activeTitle':''">
                        <p>¿Cómo pago mi préstamo a SPEI?</p>
                        <i v-if="activeNames.indexOf(3)==-1" class="el-icon-caret-bottom"></i>
                        <i v-if="activeNames.indexOf(3)>-1" class="el-icon-caret-top"></i>
                    </div>
                </template>
                <div class="collapseCont">
                    <div>(1) Abre la aplicación de su banco o ingrese a página de internet y seleccione "Transferir";</div>
                    <div>(2) Seleccione la cuenta origen;</div>
                    <div>(3) Seleccione "Agregar un nuevo destinatario";</div>
                    <div>(4) Ingrese el número de cuenta CLABE de Superapoyo;</div>
                    <div>(5) Ingrese "Superapoyo" como nombre del Beneficiario y nombre de alias del contracto;</div>
                    <div>(6) Seleccione el monto de su pago y reálicelo;</div>
                    <div>(7) ¡Transferencia exitosa! Los pagos tardarán hasta 2 horas en reflejarse en Superapoyo, también puede copiar directamente la información  de transferencia desde Superapoyo.</div>
                    <!---->
                    <!--<div><span style="color:red">*Por ejemplo:</span>  Los datos que debe colocar en su banca móvil son: BANCO: STP; BENEFICIARIO: Superapoyo; CONCEPTO: PAGO DE PRÉSTAMO; CLABE: 646***2469********.</div>-->
                    <!---->
                </div>
            </el-collapse-item>

          <!--<el-collapse-item  :name="1">
             <template slot="title">
               <transition name="slide-fade">
             <div class="collapseDiv" :class="activeNames.indexOf(1)>-1?'activeTitle':''">
               <p>¿Cómo pago mi préstamo en OXXO?</p>
               <i v-if="activeNames.indexOf(1)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(1)>-1" class="el-icon-caret-top"></i>
             </div>
               </transition>
            </template>
            <div class="collapseCont">
              <div>(1) Abra la APP y verá su número de referencia de pago OXXO, es único y personal. Guárdelo ya que ese número será lo mismo para todos sus pagos en OXXO;</div>
              <div>(2) Realice su pago por OXXO PAY en la tienda OXXO;</div>
              <div>(3) De el número de referencia;</div>
              <div>(4) Confirme que el beneficiario sea Superapoyo;</div>
              <div>(5) Pague en efectivo y guarde el ticket, ¡y listo!</div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="2">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(2)>-1?'activeTitle':''">
               <p>¿Qué puedo hacer si no puedo pagar en OXXO?</p>
               <i v-if="activeNames.indexOf(2)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(2)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>(1) Si tiene problemas al pagar su préstamo en OXXO, ¡no se preocupe! Le recomendamos pagar por transferencia electrónica, que es más fácil y econónica.</div>
              <div>(2) En la aplicación debería poner la información de transferencia únicamente por la primera vez (el número de CLABE que ingrese debe ser 646***2469********). </div>
              <div>(3) Recuerde que no importa si paga desde la aplicación de su banco o en OXXO,  puede pedir otro préstamo inmediatamente después de ver reflejado su pago.</div>
            </div>
          </el-collapse-item>-->

        </el-collapse>
      </div>
      <div style="width:1075px;border-top:1px dashed #979797;padding-top:40px" v-if="changeBtn==4">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item  :name="1">
             <template slot="title">
               <transition name="slide-fade">
             <div class="collapseDiv" :class="activeNames.indexOf(1)>-1?'activeTitle':''">
               <p>Me rechazaron mi préstamo, ¿por qué?</p>
               <i v-if="activeNames.indexOf(1)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(1)>-1" class="el-icon-caret-top"></i>
             </div>
               </transition>
            </template>
            <div class="collapseCont">Lamentamos que su solicitud haya sido rechazada; esto significa que no podrá utilizar nuestros servicios por ahora. ¡No se preocupe! En esta ocasión no fue candidato para un préstamo, pero esperamos que pronto se una a Superapoyo. En cuanto sea elegible de nuevo, la app le mandará una notificación a su celular para realizar una nueva solicitud.</div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="2">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(2)>-1?'activeTitle':''">
               <p>Realicé el pago a tiempo (por adelantado), y me rechazaron mi próximo préstamo, ¿por qué?</p>
               <i v-if="activeNames.indexOf(2)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(2)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
                Lamentamos que su solicitud haya sido rechazada. Hay varias razones por las que esto sucede. Para tener una mejor comprensión de lo sucedido, puede contactarnos al 5585265954 de 9:00 a 18:00 en horario laboral o al correo atc.superapoyo2@gmail.com, indicando nombre y telefono registrado para que podamos brindarle un servicio personalizado.
              </div>
            </div>
          </el-collapse-item>
           <el-collapse-item title="Trámites indispensables:" :name="3">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(3)>-1?'activeTitle':''">
               <p>Realicé el pago, sin embargo, en la App no ha disminuido la cantidad pendiente de reembolso. ¿Qué hago?</p>
               <i v-if="activeNames.indexOf(3)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(3)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
Lamentamos que se haya encontrado con este problema. Favor de tener más paciencia, porque los pagos en Oxxo pueden tardar hasta 24 horas en verse reflejados. Y las transferencias interbancarias pueden tardar hasta 72 horas. Si pasado ese tiempo, no ve su pago reflejado, por favor contáctenos al teléfono 5585265954 en un horario de 9:00 am a 6:00 pm o por correo atc.superapoyo2@gmail.com con copia de su comprobante de pago y con gusto le ayudamos.              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="4">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(4)>-1?'activeTitle':''">
               <p>Accidentalmente pagué en exceso, ¿qué hago?  </p>
               <i v-if="activeNames.indexOf(4)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(4)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
No se preocupe, el dinero en exceso se depositará automáticamente en el saldo de su cuenta de Superapoyo y se puede utilizar para el pago.              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="5">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(5)>-1?'activeTitle':''">
               <p>Me quedo bloqueado al completar la información y no puedo continuar con el siguiente paso. ¿Qué hago? </p>
               <i v-if="activeNames.indexOf(5)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(5)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
               Lamentamos que se haya encontrado con este problema. Favor de verificar su red o vuelva a descargar la App para intentar completar su información. Si aún no se resuelve, envíe un correo electrónico a atc.superapoyo2@gmail.com con copia de su comprobante, le responderemos lo antes posible.</div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="6">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(6)>-1?'activeTitle':''">
               <p>La App me devolvió una interfaz de error, ¿qué hago? </p>
               <i v-if="activeNames.indexOf(6)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(6)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
             En este caso, le recomendamos que primero compruebe si la red funciona con normalidad o intente actualizar APP a la última versión. Si el problema persiste,  puede contactarnos al 5585265954 de 9:00 a 18:00 en horario laboral o al correo atc.superapoyo2@gmail.com y proporcione la siguiente información: su nombre completo, ID de cliente, descripción de problema y la captura de pantalla que muestra la falla.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="7">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(7)>-1?'activeTitle':''">
               <p>Quiero cambiar mi cuenta bancaria, ¿qué hago?</p>
               <i v-if="activeNames.indexOf(7)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(7)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
               Si no ha solicitado un préstamo, puede modificar la cuenta bancaria en la página “Yo-Información personal”; si ya ha enviado la solicitud, no necesita cambiar su tarjeta bancaria, puede usar cualquier tarjeta bancaria para realizar el pago; cuando liquide su pedido pendiente, también puede modificar la cuenta bancaria en la página “Yo-Información personal” antes de volver a solicitar un préstamo.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="8">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(8)>-1?'activeTitle':''">
               <p> Quiero cambiar mi número de celular, ¿qué hago? </p>
               <i v-if="activeNames.indexOf(8)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(8)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
                Si desea cambiar su número de celular cuando está en el paso de registro, puede registrarse con su nuevo número de celular; si le queda un pedido por pagar, puede iniciar la sesión con su número de celular y la contraseña correspondiente; Si tiene un caso particular, por favor contáctenos al teléfono 5585265954 en un horario de 9:00 am a 6:00 pm o por correo atc.superapoyo2@gmail.com
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="9">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(9)>-1?'activeTitle':''">
               <p>¿Cómo completar la información bancaria para el pago del préstamo?</p>
               <i v-if="activeNames.indexOf(9)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(9)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
              Primero, copia la cuenta de reembolso en la interfaz de pago de APP. Luego, abra su software de pago de terceros y complete la siguiente información en secuencia. Los datos que debe colocar en su banca móvil son: BANCO: STP, BENEFICIARIO: Superapoyo, CONCEPTO: PAGO DE PRÉSTAMO.              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Trámites indispensables:" :name="10">
            <template slot="title">
             <div class="collapseDiv" :class="activeNames.indexOf(10)>-1?'activeTitle':''">
               <p>¿Cómo puedo aumentar el monto de préstamo?</p>
               <i v-if="activeNames.indexOf(10)==-1" class="el-icon-caret-bottom"></i>
               <i v-if="activeNames.indexOf(10)>-1" class="el-icon-caret-top"></i>
             </div>
            </template>
            <div class="collapseCont">
              <div>
Nuestro sistema evalúa el monto según su crédito. Por favor, mantenga un historial crediticio bueno y constante, lo que le ayudará a aumentar el monto de su préstamo. Sin embargo, estos datos se basan en el puntaje integral del sistema y no se permiten factores humanos. Esperamos su comprensión. Si tiene circunstancias especiales, puede contactarnos al 5585265954 de 9:00 a 18:00 en horario laboral o al correo atc.superapoyo2@gmail.com. </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/ayuda.scss";

export default {
  name: "ayuda",
  components: { headNav, footPage },
  data() {
    return {
      activeNames:[],
      changeBtn:1,
      activeName:'one',
      btnList:[
        {
          label:'Requisito',
          id:1
        },
        {
          label:'Solicitud',
          id:2
        },
        {
          label:'Forma de pago',
          id:3
        },
        {
          label:'Preguntas excepcionales',
          id:4
        }
      ]
    }
  },
  watch:{
    changeBtn(){
      this.activeNames=[]
    }
  },
  methods:{
    handleChange(){
      console.log(this.activeNames);
    },
    changeCont(id){
      this.changeBtn=id

    }
  }
}
</script>

<style scoped lang="scss">
.ayuda{
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  background: #fbfbfb;
}
.content{
  flex: 1;
  z-index: 99;
  width: 1200px;
  height: 956px;
  margin: 164px auto 0;
  background-color: #ffffff;
  padding-top: 37px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 60px;
  align-items: center;
  img{
    width: 1075px;
    height: 139px;
  }
}
.answer span {
  font-weight: 700;
}

button{
  display: inline-block;
  padding: 12px 34px;
  font-weight: 400;
   border: none;
   color: #E7E6E6;
   background: #fff;
   cursor: pointer;
    font-size: 18px;
  border-radius: 24px;
  box-shadow: 0px 2px 11px 0px #E7E6E6;;
}
.is-active button{
  box-shadow: 5px 6px 15px 0px rgba(11, 146, 36, 0.37);;
  background: linear-gradient(90deg, #34C744 0%, #01851C 100%);
  color: #fff;

}
ul{
  list-style: none;
}
.btnList{
  display: flex;
  width: 778px;
  justify-content: space-between;
  margin-top: 44px;
  padding-bottom: 15px;
  // border-bottom: 1px #979797 dashed;
}
.collapseDiv{
  background: #f8f8f8;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-radius: 4px;
}
/deep/ .el-collapse-item__arrow{
  display: none;
}
/deep/ .el-collapse-item__header{
  border-bottom: none;
}
.el-collapse-item{
  margin-bottom: 18px;

}
.el-collapse{
  border-top: none;
}
.collapseCont{
    background: #f8f8f8;
    padding: 20px 33px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #edecec;
}
.activeTitle{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.el-collapse{
  margin-bottom: 61px;
}
</style>
