<template>
	<div class="Inicio">
		<div class="banner">
			<!-- <img class="banner_img" src="@/assets/image/bg_1.jpg" alt=""> -->
			<div class="mainContent">
				<a
					href="https://bit.ly/4cvDIyo"
					class="playBtn"
					target="_blank"
					><img src="@/assets/image/playBtnv2.png" alt=""
				/></a>
			</div>
		</div>

		<div class="p2">
			<div class="mainContent twoContent">
				<div class="title"><img src="../assets/image/jk_ico.png" alt="" />Solicitar un préstamo</div>
				<div class="loan-content-box">
					<div class="loan-select-box">
						<!-- <div class="loan-select-item">7 Días</div> -->
						<div class="loan-select-item" :class="isActive == 61 ? 'selected' : ''" @click="getDays(61)">
							61 Días
						</div>
						<div class="loan-select-item" :class="isActive == 90 ? 'selected' : ''" @click="getDays(90)">
							90 Días
						</div>
						<div class="loan-select-item" :class="isActive == 180 ? 'selected' : ''" @click="getDays(180)">
							180 Días
						</div>
					</div>
					<div class="moneybox">
						$ <span id="money">{{ dealNumber(sliderValue) }}</span>
					</div>
					<div class="sliderBox">
						<el-slider v-model="sliderValue" :min="1000" :max="20000" :show-tooltip="false"></el-slider>
						<div class="range">
							<p>$ <span>1,000</span></p>
							<p>$ <span>20,000</span></p>
						</div>
					</div>

					<div class="dateBox">
						<img src="@/assets/image/date_icon.png" alt="" />
						<div class="date">
							Fecha de vencimiento：<span>{{ addDate }}</span>
						</div>
					</div>

					<a
						href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.efectivo"
						target="_blank"
						class="downloadBtn"
						><button class="solicitarBtn">Solicitar un préstamo</button></a
					>
				</div>
			</div>
		</div>

		<div class="p3">
			<div class="mainContent">
				<div class="title"><img src="../assets/image/wh_ico.png" alt="" /> ¿Por qué elegirnos?</div>
				<div class="content">
					<div class="item">
						<img src="@/assets/image/p3_1.png" alt="" />
						<p class="item-title">Monto alto del préstamo</p>
						<div class="line"></div>
						<p class="item-subtitle">El monto del préstamo puede ser de hasta <span>$20,000</span></p>
					</div>
					<div class="item">
						<img src="@/assets/image/p3_2.png" alt="" />
						<p class="item-title">Proceso de solicitud sencillo</p>
						<div class="line"></div>
						<p class="item-subtitle">Aplicación rápida</p>
						<p class="item-subtitle">Tasa de aprobación alta</p>
						<p class="item-subtitle">Depósito rápido</p>
					</div>
					<div class="item">
						<img src="@/assets/image/p3_3.png" alt="" />
						<p class="item-title">100% protección de seguridad de datos</p>
						<div class="line"></div>
						<p style="width: 300px" class="item-subtitle">
							Superapoyo protege la seguridad de sus datos las 24 horas del día
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="p4">
			<div class="mainContent">
				<div class="title"><img src="../assets/image/dh_ico.png" alt="" /> Proceso de préstamo</div>

				<div class="content">
					<div
						class="item"
						:class="changeNum == 1 ? 'activeItem' : ''"
						@click="changeList(1)"
						style="background: #f5f5f5"
					>
						<img v-if="changeNum == 1" src="../assets/image/oneList1.png" alt="" />
						<img v-else src="../assets/image/p4_1.png" alt="" />
						<p class="item-title">Descarga la App</p>

						<p class="item-subtitle">
							Vaya a
							<a
								target="_blank"
								style="color: #1fa848"
								href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.efectivo"
								><u>GooglePlay</u></a
							>
							para descargar
						</p>
						<p class="item-subtitle">Superapoyo</p>
						<div class="number">1</div>
					</div>
					<div class="item" :class="changeNum == 2 ? 'activeItem' : ''" @click="changeList(2)">
						<img v-if="changeNum == 2" src="../assets/image/oneList2.png" alt="" />
						<img v-else src="../assets/image/p4_2.png" alt="" />
						<p class="item-title">Proceso de solicitud sencillo</p>
						<p class="item-subtitle">*Cree su cuenta</p>
						<p class="item-subtitle">* Solo 4 pasos y no más de 3 minutos</p>
						<p class="item-subtitle">para solicitar su préstamo</p>
						<div class="number">2</div>
					</div>
					<div
						class="item"
						:class="changeNum == 3 ? 'activeItem' : ''"
						@click="changeList(3)"
						style="background: #f5f5f5"
					>
						<img v-if="changeNum == 3" src="../assets/image/oneList3.png" alt="" />
						<img v-else src="../assets/image/p4_3.png" alt="" />
						<p class="item-title"><span style="color: #1fa848">100%</span> solicitud online</p>
						<p class="item-subtitle">
							* Aplicación móvil en línea las 24 horas
						</p>
						<p class="item-subtitle">
							en cualquier momento y en cualquier lugar
						</p>
						<div class="number">3</div>
					</div>
					<div class="item" :class="changeNum == 4 ? 'activeItem' : ''" @click="changeList(4)">
						<img v-if="changeNum == 4" src="../assets/image/oneList4.png" alt="" />
						<img v-else src="../assets/image/p4_4.png" alt="" />
						<p class="item-title">Depósito exitoso</p>
						<p class="item-subtitle">
							* El préstamo se depositará directamente
						</p>
						<p class="item-subtitle">en su cuenta bancaria</p>
						<p class="item-subtitle">* Después de realizar su pago a tiempo.</p>
						<p class="item-subtitle">Puede obtener un préstamo más alto,</p>
						<p class="item-subtitle">
							el tipo de interés más bajo y los términos de préstamo más flexibles.
						</p>
						<div class="number">4</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p5">
			<div class="title"><img src="../assets/image/ss_ico.png" alt="" />Superapoyo siempre está a su lado</div>
			<video width="1200px" muted loop autoplay="autoplay" controls src="../assets/video/test2.mp4"></video>
		</div>
		<div class="p6">
			<div class="mainContent" style="text-align: center">
				<div class="title"><img src="../assets/image/kf_ico.png" alt="" />Contáctanos</div>
				<p class="item-title">
					Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de atención al cliente.
				</p>
				<div class="item">
					<div class="left">
						<img src="../assets/image/p6_avatar.png" alt="" />
					</div>
					<img class="line" src="../assets/image/p6_line.png" alt="" />
					<div class="right">
						<ul class="sixList">
							<li>
								<img src="../assets/image/p6_1.png" alt="" />
								<div>
									<p class="one">Atención al Cliente：</p>
									<p class="two">5585265954</p>
								</div>
							</li>
							<!--<li>
								<img src="../assets/image/p6_5.png" alt="" />
								<div>
									<p class="one">Especialistas en ventas:</p>
									<p class="two">Fanny Sosa: +527226783581</p>
									<p class="two">Fatima Mendoza: +525580590515</p>
								</div>
							</li>-->
							<li>
								<img src="../assets/image/p6_2.png" alt="" />
								<div>
									<p class="one">Email：</p>
									<p class="two">atc.superapoyo2@gmail.com</p>
									<p class="two">superapoyo.cobranza.mx@gmail.com</p>
								</div>
							</li>
							<li>
								<img src="../assets/image/p6_3.png" alt="" />
								<div>
									<p class="one">Dirección de la oficina:</p>
									<p class="two">
										Versalles No.15, 4th floor, 401, Cuauhtémoc,Ciudad de México
									</p>
								</div>
							</li>
							<li>
								<img src="../assets/image/p6_4.png" alt="" />
								<div>
									<p class="one">Tiempo laboral：</p>
									<p class="two">De lunes a viernes 9:00-18:30</p>
									<p class="two">De sábado a domingo 9:00-14:30</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="AVISO IMPORTANTE" :visible.sync="dialogVisible" width="30%">
			<span slot="footer" class="dialog-footer">
				<p>
					Te informarnos que el número de referencia o cuenta para pagar tu crédito es únicamente el que aparece en tu
					acceso a la aplicación. Superapoyo nunca te contactará por otro medio para proporcionarte una cuenta distinta
					a la que se indica en tu aplicación. Para evitar que seas víctima de algún fraude, te sugerimos ser cuidadoso
					y verificar el número de referencia o cuenta en la que pagarás tu crédito. Para cualquier duda o aclaración
					puedes comunicarte con nuestro departamento de Atención a Clientes.
				</p>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import './scss/Inicio.scss'

	export default {
		name: 'Inicio',
		data() {
			return {
				changeNum: 0,
				sliderValue: 5000,
				currentdate: '',
				addDate: '',
				isActive: 61,
				dialogVisible: true,
			}
		},
		created() {
			this.getNowFormatDate()
			if (this.$route.query.dialogVisible == 1) {
				this.dialogVisible = false
			}
		},
		methods: {
			changeList(index) {
				this.changeNum = index
			},
			getDays(day) {
				this.isActive = day
				this.dateAddDays(this.currentdate, day)
			},
			dealNumber(num) {
				if (num != 0 && num) {
					num = num + ''
					let splitList = num.split('.')
					//将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
					let intStrList = splitList[0]
						.split('')
						.reverse()
						.join('')
						.match(/(\d{1,3})/g)
					//将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
					let intStr = intStrList
						.join(',')
						.split('')
						.reverse()
						.join('')
					return intStr
				}
				return num
			},
			dateAddDays(dateStr, dayCount) {
				var tempDate = new Date(dateStr.replace(/-/g, '/')) //把日期字符串转换成日期格式
				var resultDate = new Date((tempDate / 1000 + 86400 * dayCount) * 1000) //增加n天后的日期
				let mounth = (resultDate.getMonth() + 1).toString()
				if (mounth.length == 1) {
					mounth = '0' + mounth
				}
				let today = resultDate.getDate().toString()
				if (today.length == 1) {
					today = '0' + today
				}

				var resultDateStr = today + '-' + mounth + '-' + resultDate.getFullYear() //将日期转化为字符串格式
				this.addDate = resultDateStr
			},
			getNowFormatDate() {
				var date = new Date()
				var seperator1 = '-'
				var year = date.getFullYear()
				var month = date.getMonth() + 1
				var strDate = date.getDate()
				if (month >= 1 && month <= 9) {
					month = '0' + month
				}
				if (strDate >= 0 && strDate <= 9) {
					strDate = '0' + strDate
				}
				var currentdate = year + seperator1 + month + seperator1 + strDate
				this.currentdate = currentdate
				this.getDays(90)
			},
		},
	}
</script>

<style lang="scss">
	.p4 .item {
		cursor: pointer;
	}
	.activeItem {
		background: url('../assets/image/oneListBg.png') !important;
		background-position: cover;
		background-size: 100% 100% !important;
		box-shadow: 0px 0px 5px green;
		color: #fff;
		.item-title {
			color: #fff !important;
		}
		.item-subtitle {
			color: #ffffff !important;
		}
		.number {
			color: #159f3f;
			background: linear-gradient(360deg, #c9ffd8 0%, #ffffff 100%);
			box-shadow: 5px 3px 13px 0px rgba(30, 179, 72, 0.32), 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
		}
		a,
		span {
			color: #fefefe !important;
		}
	}
	video {
		width: 1000px !important;
		height: auto !important;
		margin-top: 100px !important;
	}
	.p4 .item-subtitle {
		line-height: 24px;
	}
	.playBtn img {
		height: auto !important;
	}
</style>
